import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = ({ data }) => (
  <Layout>
    <div className="max-w-screen-sm mx-auto py-8">
      <SEO title="Home" />
      <h1 className="text-2xl font-bold pb-4">
        Hey{" "}
        <span role="img" aria-label="wave">
          👋
        </span>
      </h1>
      <div className="pb-4">
        <p className="pb-2">
          My name is
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            Filipe
          </span>.
        </p>
        <p className="pb-2">
          Welcome to my corner of the internet. I'm a
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            software engineer
          </span>.
        </p>
        <p className="pb-2">
          I think of myself as a generalist and can transfer the knowledge I
          gathered over the years to multiple areas and business domains. I
          like to solve challenges in efficient and creative ways.
        </p>
        <p className="pb-2">
          Throughout a day of work, you may find me fiddling with
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            JavaScript
          </span>
          and
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            React
          </span>
          , creating a rich web application or using React-Native to
          develop a native app.
        </p>
        <p>
          More often than not you can also find me working with
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            Node.js
          </span>,
          finding ways to have a better developer experience (
          <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            DX
          </span>
          ) or improving the infrastructure deployment (
            <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            AWS
            </span>
            ,
            <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            GCP
            </span>
            ) and pipelines (
            <span className="bg-blue-100 text-blue-800 mx-1 p-1">
            CI / CD
            </span>
            ).
        </p>
      </div>
      <div className="py-4">
        <p>You can find me at</p>
        <ul className="list-disc list-inside py-1">
          <li className="underline py-1">
            <a href="//dev.to/fampinheiro">dev.to</a>
          </li>
          <li className="underline py-1">
            <a href="//twitter.com/fampinheiro">twitter.com</a>
          </li>
          <li className="underline py-1">
            <a href="//linkedin.com/in/fampinheiro">linkedin.com</a>
          </li>
          <li className="underline py-1">
            <a href="//github.com/fampinheiro">github.com</a>
          </li>
        </ul>
        <p>
          Or drop me an email at{" "}
          <span className="bg-blue-100 text-blue-800 p-1">
            hey [at] fampinheiro.com
          </span>
        </p>
      </div>
      <div className="flex flex-row justify-center py-4">
        <div style={{ maxWidth: "125px", width: "100%" }}>
          <a href="https://www.youracclaim.com/badges/d7320c85-a1de-48c8-b586-c805f359d4e5">
            <Image fluid={data.file.childImageSharp.fluid} />
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    file(relativePath: { eq: "cka.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;

export default IndexPage;
